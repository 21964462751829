import React from "react"
import ContactForm from "../components/contact/ContactForm"

const HelpPage = () => {
  return (
    <>
      <p className="is-size-5">
        Have a question? Comment? Run into a bug? Please let us know using the form below or by emailing us directly at{" "}
        <a href="mailto:andrew@jiggswap.com">andrew@jiggswap.com</a>.
      </p>

      <ContactForm />
    </>
  )
}

export default HelpPage
